import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'

import Jumbotron from 'react-bootstrap/Jumbotron'
import Container from 'react-bootstrap/Container'
import '../styles/global.css'

export const PageTemplate = ({
  title,
  subtitle,
  image,
  content,
  contentComponent,
}) => {
  const PageContent = contentComponent || Content

  return (
    <Container>
      <Jumbotron
        className="mt-3"
        style={{
          backgroundImage: `url(${image})`,
        }}
      ></Jumbotron>
      <section>
        <h2>
          { title }
          { !!subtitle &&
            <Fragment>
              { " " }
              <small class="text-muted">{ subtitle }</small>
            </Fragment>
          }
        </h2>

        <PageContent className="content" content={ content } />
      </section>
    </Container>
  )
}

PageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

const Page = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <Helmet titleTemplate={ `${post.frontmatter.title} - %s` } />
      <PageTemplate
        contentComponent={ HTMLContent }
        title={ post.frontmatter.title }
        subtitle={ post.frontmatter.subtitle }
        image={ post.frontmatter.image }
        content={ post.html }
      />
    </Layout>
  )
}

Page.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Page

export const pageQuery = graphql`
  query Page($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        subtitle
        image
      }
    }
  }
`
