import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import Layout from '../components/Layout'
import { HTMLContent } from '../components/Content'

import { PageTemplate } from "../templates/page"
import '../styles/global.css'

const Biography = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <Helmet titleTemplate={ `${post.frontmatter.title} - %s` } />
      <PageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        image={post.frontmatter.image}
        content={post.html}
      />
    </Layout>
  )
}

Biography.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Biography

export const biographyQuery = graphql`
  query Biography($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        image
      }
    }
  }
`
